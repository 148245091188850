import { AppLink } from "@/components/AppLink";
import { Show } from "@/components/Show";
import { AppUserProfilePublic } from "@/lib/api/models";
import { IconBrandInstagram } from "@/lib/icons/tabler/IconBrandInstagram";
import { IconBrandYoutube } from "@/lib/icons/tabler/IconBrandYoutube";
import { IconCalendarCheck } from "@/lib/icons/tabler/IconCalendarCheck";
import { IconWorldWww } from "@/lib/icons/tabler/IconWorldWww";
import { t } from "@/lib/translations";
import { Button, rem, Tooltip } from "@mantine/core";

export function CoachAppoinmentButton({ user, className }: { user: AppUserProfilePublic; className?: string }) {
  return (
    <AppLink to={`/booking?coach=${user.userId}`}>
      <Button
        variant="gradient"
        gradient={{ from: "pink", to: "orange", deg: 45 }}
        size="xs"
        className={className}
        data-umami-event={`coach-make-appointment-button`}
        data-umami-event-coach-id={user.userId}
        rightSection={<IconCalendarCheck style={{ width: rem(20), height: rem(20) }} />}
      >
        {t("HOME.TEAM.MAKE_APPOINTMENT_BUTTON")}
      </Button>
    </AppLink>
  );
}

export function CoachLanguages({ user }: { user: AppUserProfilePublic }) {
  return user.about.languages?.map((l) => {
    return (
      <Tooltip key={l} label={t("HOME.TEAM.COACH_LANG_TOOLTIP_" + l.toUpperCase())}>
        <div className="rounded-md opacity-70 cursor-pointer">{t("HOME.TEAM.COACH_LANG_LABEL_" + l.toUpperCase())}</div>
      </Tooltip>
    );
  });
}

export function CoachLinks({ user }: { user: AppUserProfilePublic }) {
  return (
    <div>
      <Show show={!!user.instagramUrl}>
        <Tooltip label="Instagram">
          <a href={user.instagramUrl} target="_blank" rel="noreferrer">
            <Button variant="subtle" size="icon" title="Instagram">
              <IconBrandInstagram width={20} height={20} />
            </Button>
          </a>
        </Tooltip>
      </Show>
      <Show show={!!user.youtubeUrl}>
        <Tooltip label="YouTube">
          <a href={user.youtubeUrl} target="_blank" rel="noreferrer">
            <Button variant="subtle" size="icon" title="YouTube">
              <IconBrandYoutube width={20} height={20} />
            </Button>
          </a>
        </Tooltip>
      </Show>
      <Show show={!!user.websiteUrl}>
        <Tooltip label="Strona internetowa">
          <a href={user.websiteUrl} target="_blank" rel="noreferrer">
            <Button variant="subtle" size="icon" title="website">
              <IconWorldWww width={20} height={20} />
            </Button>
          </a>
        </Tooltip>
      </Show>
    </div>
  );
}
